<template>
  <div ref="container" class="image-container" @mousedown="startDrag" @touchstart="startDrag" @wheel="handleWheel">
    <div ref="wrapper" :style="wrapperStyle" class="image-wrapper">
      <slot></slot>
    </div>
    <div class="controls">
      <div class="controls__item zoom" v-if="showScale">
        <span>zoom: {{ Math.round(scale * 100) }}%</span>
      </div>
      <button class="controls__item" title="" @click="resetZoom">
        <i class="ez-icon-refresh"></i>
        <span>reset</span>
      </button>
      <button class="controls__item" title="" @click="downloadImage">
        <i class="ez-icon-download-from-cloud"></i>
        <span>download</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageZoom',

  props: {
    minScale: {
      type: Number,
      default: 0.5
    },
    maxScale: {
      type: Number,
      default: 5
    },
    scaleStep: {
      type: Number,
      default: 0.25
    },
    showScale: {
      type: Boolean,
      default: true
    },
    fileName: {
      type: String,
      default: 'image'
    }
  },

  data() {
    return {
      scale: 1.5,
      position: { x: 0, y: 0 },
      dragStart: { x: 0, y: 0 },
      positionStart: { x: 0, y: 0 },
      isDragging: false
    };
  },

  computed: {
    wrapperStyle() {
      if ( this.position.x === 0 && this.position.y === 0 && this.scale === 1 ) {
        return {
          transform: 'translate(-50%, -50%) scale(1)'
        };
      } else {
        return {
          transform: `translate(calc(-50% + ${ this.position.x }px), calc(-50% + ${ this.position.y }px)) scale(${ this.scale })`
        };
      }
    }
  },

  mounted() {
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDrag);
    window.addEventListener('touchmove', this.onDrag);
    window.addEventListener('touchend', this.stopDrag);
  },

  beforeDestroy() {
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDrag);
    window.removeEventListener('touchmove', this.onDrag);
    window.removeEventListener('touchend', this.stopDrag);
  },

  methods: {
    resetZoom() {
      this.scale = 1.5;
      this.position = { x: 0, y: 0 };
    },

    downloadImage() {
      const imageElement = this.$el.querySelector('img');

      if ( !imageElement ) {
        console.error('No image found to download');
        return;
      }

      const imageUrl = imageElement.src;

      if ( !imageUrl ) {
        console.error('The image does not have a valid URL');
        return;
      }

      const link = document.createElement('a');
      link.href = imageUrl;

      const extension = imageUrl.split('.').pop().split('?')[0] || 'jpg';
      link.download = `${ this.fileName }.${ extension }`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    handleWheel(event) {
      event.preventDefault();
      const delta = event.deltaY > 0 ? -1 : 1;
      const newScale = this.scale + (delta * this.scaleStep);

      if ( newScale < this.minScale || newScale > this.maxScale ) {
        return;
      }

      const container = this.$refs.container;
      const rect = container.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const x = mouseX - container.offsetWidth / 2 - this.position.x;
      const y = mouseY - container.offsetHeight / 2 - this.position.y;

      const factor = newScale / this.scale;
      const newPosition = {
        x: this.position.x - (x * (factor - 1)),
        y: this.position.y - (y * (factor - 1))
      };

      this.scale = newScale;
      this.position = newPosition;
    },

    startDrag(event) {
      this.isDragging = true;

      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      const clientY = event.touches ? event.touches[0].clientY : event.clientY;

      this.dragStart = { x: clientX, y: clientY };
      this.positionStart = { x: this.position.x, y: this.position.y };

      if ( !event.touches ) {
        event.preventDefault();
      }
    },

    onDrag(event) {
      if ( !this.isDragging ) return;

      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      const clientY = event.touches ? event.touches[0].clientY : event.clientY;

      const dx = clientX - this.dragStart.x;
      const dy = clientY - this.dragStart.y;

      this.position = {
        x: this.positionStart.x + dx,
        y: this.positionStart.y + dy
      };

      event.preventDefault();
    },

    stopDrag() {
      this.isDragging = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.image-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  will-change: transform;
}

.controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  &__item {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 4px;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    transition: background-color 0.2s;
    border: none;
    width: fit-content;
    display: flex;
    gap: .2rem;

    &.zoom {
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    span {
      font-size: 0.8125rem;
    }

    i {
      font-size: 1.25rem;
      color: white;
    }
  }
}
</style>